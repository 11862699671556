import React from "react"
import PreHeading from "../PreHeading"

export const ValueProp: React.FC = ({ children }) => {
    return <section className="section-x-margin section-x-padding">
        {children}
    </section>
}

interface ValuePropHeaderProps {
    preHeading: string
    heading: string
}
export const ValuePropHeader: React.FC<ValuePropHeaderProps> = ({ preHeading, heading, children }) => {
    return <div className="mb-16">
        <div className="centeredText">
            <PreHeading inline>{preHeading}</PreHeading>
            <h2>{heading}</h2>
            {children}
        </div>
    </div>
}
