import React, { ReactNode } from "react"

import Style from "./Quote.module.css"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import { ImageFixed } from "../../helpers/Image"


interface QuoteProps<T = ReactNode> {
    cite: string
    logo?: T
    logoFirst?: boolean
    logoClass?: string
    quoteClassName?: string
}
export const QuoteWithMedia: React.FC<QuoteProps> = ({ children, cite, logo, logoFirst = false, logoClass= "", quoteClassName }) => {
    return <blockquote className={Style.quoteWrap}>
        <div className={Style.quote}>
            <span className={quoteClassName}>{children}</span>
        </div>
        <cite className={Style.cite}>
            {!logoFirst && cite}
            {logo && <div className={`${Style.logo} ${logoClass}`}>
                {logo}
            </div>}
            {logoFirst && cite}
        </cite>
    </blockquote>
}

export const Quote: React.FC<QuoteProps<ImageFixed>> = ({ logo, ...props }) => {
    return <QuoteWithMedia {...props} logo={logo && <Img fixed={logo.childImageSharp.fixed} alt="" />} />
}

export const query = graphql`
    fragment QuoteCiteImage on File {
        childImageSharp {
            fixed(width: 80, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
        }
    }
`
