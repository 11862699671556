import React from "react"
import PreHeading from "./PreHeading"
import { DateOverlayButton } from "./DateOverlay/DateOverlayButton"

interface StartNowProps {
    title?: string
}

export const StartNowDe: React.FC<StartNowProps> = ({ title = "Schaffen Sie Ihre Wertebotschafter" }) => {
    return <section className="fancy-content-lg">
        <div className="centeredText">
            <PreHeading inline>Jetzt starten</PreHeading>
            <h2>{title}</h2>
            <p>
                Quadio Kunden lieben es ihr eigenes digitales Potenzial zu ermitteln und es endlich zu nutzen. Selbst gestalten, statt dem Zufall überlassen und sich begleiten  lassen, statt alleine gehen zu müssen.
            </p>

            <DateOverlayButton appearance="primary">Termin vereinbaren</DateOverlayButton>
        </div>
    </section>
}

export const StartNowEn: React.FC<StartNowProps> = ({ title = "Create Your Core Value Ambassadors" }) => {
    return <section className="fancy-content-lg">
        <div className="centeredText">
            <PreHeading inline>Start Now</PreHeading>
            <h2>{title}</h2>
            <p>
                Quadio customers seek measuring their own digital skills encouraged by ultimately utilizing and applying the tool. Create and control it on your own, rather than leaving chance determining it. Be guided, rather than being left alone.
            </p>

            <DateOverlayButton appearance="primary">Make an Inquiry</DateOverlayButton>
        </div>
    </section>
}
