import React from "react"
import Layout from "../components/Layout"
import { ValueProp, ValuePropHeader } from "../components/ValueProp/ValueProp"
import Awards from "../components/Awards/Awards"
import { graphql, useStaticQuery } from "gatsby"
import ImageAndText from "../components/ImageAndText/ImageAndText"
import PreHeading from "../components/PreHeading"
import { ButtonA } from "../components/Button/Button"
import SoloToOchestra from "../components/SoloToOrchestra/SoloToOrchestra.de"
import CustomerSlider from "../components/CustomerSlider"
import { Quote } from "../components/Quote/Quote"
import { StartNowDe } from "../components/StartNow"
import { PageType } from "../index"
import Seo from "../components/Seo"

const AboutUsDe: PageType = ({ location }) => {
    const {
        jetShow,
        jetShowTablet,

        teubert,
    } = useStaticQuery(graphql`
        query AboutUsPage {
            jetShow: file(relativePath: { eq: "pages/about-us/jet-show.jpg" }) {
                ...ImageAndTextSideImage
            }
            jetShowTablet: file(relativePath: { eq: "pages/about-us/jet-show.jpg" }) {
                ...ImageAndTextSideImage_Tablet
            }
            teubert: file(relativePath: { eq: "pages/about-us/teubert.jpg" }) {
                childImageSharp {
                    fixed(width: 80, height: 80) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
        }
    `)

    return <Layout location={location}>
        <Seo
            title="Pioniere der Unternehmensführung"
            description="Führungsexpertise, Unternehmertum, digitale Projekterfahrung und Innovation vereint in einem Unternehmen."
        />
        <section className="fancy-content-lg-dark">
            <Quote
                cite="Prof. Dr. Michael Teubert"
                logo={teubert}
                logoFirst
                logoClass="rounded-full overflow-hidden"
                quoteClassName="h2"
            >
                Gutes Führen ist nicht kompliziert, es ist komplex.
            </Quote>
        </section>
        <ValueProp>
            <ValuePropHeader
                preHeading="Über Uns"
                heading="Unsere Mission: Menschen befähigen"
            >
                <p>
                    Unsere Produkte sind darauf abgestimmt, Ihren unternehmerischen Erfolg sicherzustellen. Und da jeder Ihrer Mitarbeiter einen Einfluss darauf hat, ist es unsere Passion, sie zu befähigen und zu stärken. Wir haben herausgefunden, wie das funktioniert und wissen: Nur wer Menschen erreicht, kann etwas verändern. Wir richten unser Produkt nach den Menschen aus, die es nutzen.
                </p>

                <div className="mt-8">
                    <Awards />
                </div>
            </ValuePropHeader>
        </ValueProp>
        <section>
            <ImageAndText fancy image={jetShow} tabletImage={jetShowTablet}>
                <PreHeading>Wer vor was</PreHeading>
                <h2>Zusammen. Führen.</h2>
                <p>
                    Seit Anbeginn steht Quadio für Führungs- und Trainingsexpertise und Software auf Top-Niveau. All das wäre nicht möglich ohne unser Team, das dank unserer Kompetenzen aus Fach-Expertise, Produkt-Engineering, redaktionellem Know-How, Design und einem lebendigen Netzwerk mehr ist, als nur ein Produkt zur Stärkung Ihres Unternehmens: Es ist unsere Einstellung zu führen und zu handeln.
                </p>
                <ButtonA
                    appearance="secondary"
                    href={`mailto:info@quadio.de?subject=${encodeURIComponent("Partner werden")}`}
                >
                    Partner werden
                </ButtonA>
            </ImageAndText>
        </section>
        <SoloToOchestra />
        <section className="section-x-margin">
            <div className="hidden" aria-hidden={false}>
                Quadio Kunden
            </div>

            <CustomerSlider />
        </section>
        <StartNowDe
            title="Mit Quadio durchstarten"
        />
    </Layout>
}

export default AboutUsDe
